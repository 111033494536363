<template>
    <v-container>
        <navbar title="کد احراز هویت"/>
        <v-main class="pt-15">
            <error-alert/>
            <success-alert/>
            <v-form @submit.prevent="confirm" ref="confirm">
              <v-card class="pa-2 mt-9 card flat-card" color="surface">
                <v-text-field :rules="[ rules.required, rules.count, rules.type_number ]" type="tell" v-model="code" placeholder="کد احراز هویت" required/>
                <div class="d-flex justify-space-between align-center">
                <span v-if="time != 0" class="medium-font darkDisable--text mr-2">ارسال مجدد </span>
                <span @click="send_again" v-if="time == 0" class="medium-font info--text mr-2">ارسال مجدد </span>
                <span class="medium-font ml-2" v-if="time != 0" v-text="time"></span>
                </div>
                <v-card-actions class="pa-0 mt-3">
                    <v-spacer></v-spacer>
                    <v-btn @click="check" type="submit" class="primaryActions onPrimaryActions--text button">ورود</v-btn>
                </v-card-actions>
                </v-card>
            </v-form>
        </v-main>
    </v-container>
</template>
<script>
import navbar from '@/components/navbar/appBar'
import { confirm, resend_code } from './models/auth';
import ErrorAlert from '@/components/alerts/error.alert.vue';
import SuccessAlert from '@/components/alerts/success.alert.vue';
import { toEnglishDigits } from '@/models/changeType';
import { alertMessage } from '@/assets/messageAlert'
export default {
    components:{
       navbar,
        ErrorAlert,
        SuccessAlert
    },
    data: () => ({
      time: 0,
      code: '',
      rules: {
      required: null,
      count: value => value.length <= 20 || alertMessage.error['MaxCharacters'],
      type_number: value => {
        const mobile = toEnglishDigits(value)
        const pattern = /^[0-9]+$/
        return pattern.test(mobile) || alertMessage.error['type_number']
      },
    }
    }),
    created () {
     const register = localStorage.getItem('registerd')
     if (register) {
         this.time = 60
       setInterval(() => {
        if (this.time != 0) {
          this.time --
        }
      }, 1000) 
     }
    },
    methods: {
      check () {
      this.rules.required =
       value => !!value || alertMessage.error['require']
    },
      send_again () {
        this.time = 60
        setInterval(() => {
        if (this.time != 0) {
          this.time --
        }
      }, 1000)
        resend_code()
      },
      confirm () {
        const validation = this.$refs.confirm.validate()
        if (validation == true) {
          confirm(toEnglishDigits(this.code))
        }
      }
    }
}
</script>